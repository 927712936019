function jobAlertsOverwrite() {
    const signupText = document.createElement("div");
    signupText.textContent = "Sign up for Job Alerts!";
    signupText.id = "job-alerts-signup-text";

    const jobAlertsPopupBtn = document.querySelector(".awsm-jobs-alerts-popup-trigger-btn");

    if (jobAlertsPopupBtn) {
        jobAlertsPopupBtn.parentNode.insertBefore(signupText, jobAlertsPopupBtn);
    }
}
jobAlertsOverwrite();