/**
 * Main project app script!
 *
 * All script functionality should be in this file.
 *
 * NOTE: jQuery by default is NOT supported! Please make sure to follow ES6 paradigms and create vanilla JS
 * wherever possible. jQuery is included for the sake of plugins that we rely on such as Gravity Forms.
 * Refer to the document at https://wiki.posturedev.com for further information.
 **/

//Modules
// import { Glightbox, AOS, Swiper } from "Parent/js/modules/modules";

// All generic global site javascript should live in this file.
import Globals from "./globals";
import BetterMenu from "./lib/better-menu/better-menu";
import BetterModal from "./lib/better-menu/better-modal";
import programSelectWatcher from "./lib/program-select-watcher";
import eventTypeSelectWatcher from "./lib/event-type-select-watcher";
import SuperActiveAnchor from "./lib/super-active-anchor";
import Splide from "@splidejs/splide";
import "simplebar"; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import "simplebar/dist/simplebar.css";
import jobAlertsOverwrite from "./lib/job-alerts-overwrite";

// Attach the scripts after the website is up and running
document.addEventListener("DOMContentLoaded", () => {
	// Remove and uncomment the enqueue script in functions.php if not needed
	window.$ = jQuery();

	document.querySelector("body").setAttribute("data-s-loaded", "");

	const globals = new Globals();

	new BetterMenu();
	new BetterModal();
	new programSelectWatcher();
	new eventTypeSelectWatcher();
	new SuperActiveAnchor();
	new jobAlertsOverwrite();

	var splide = new Splide(".splide", {
		perPage: 3,
		rewind: true,
		gap: "20px",
		autoplay: true,
		breakpoints: {
			768: {
				perPage: 1,
			},
			992: {
				perPage: 2,
			},
		},
		// pagination: false,
	});

	splide.mount();

	const locationBtn = document.querySelector("#wpsl-search-btn");
	if (locationBtn) {
		locationBtn.addEventListener("click", () => {
			const resultTitle = document.querySelector("#results");

			resultTitle.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		});
	}
});
